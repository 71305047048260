.root {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.form {
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}

.section {
	min-height: 0;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	gap: 32px;
	overflow: hidden auto;

	@media (--md) {
		gap: 58px;
	}
}

.footer {
	flex-shrink: 0;
	margin-top: auto;
}

.search {
	margin-top: var(--space-xs);
}

.content {
	display: flex;
	flex-direction: column;
	gap: var(--space-xs);
}

.select {
	max-width: 100%;
}

.regions {
	margin-top: 20px;
	margin-bottom: 0;
}

.accordionContent {
	display: flex;
	flex-direction: column;
	gap: var(--space-2xs) 0;

	@media (--md) {
		gap: var(--space-xs);
	}
}

.accordionRoot {
	margin-top: var(--space-xs);
	padding-bottom: 0;

	& .accordionItem {
		padding-block: var(--space-2xs);
		border-bottom: 1px solid var(--primary-dark-blue-01);

		@media (--md) {
			padding-block: var(--space-xs);
		}

		& .accordionTrigger {
			text-transform: capitalize;
		}

		&:last-child {
			border-bottom: 0;
		}
	}
}

& .popular {
	display: flex;
	flex-direction: column;
	gap: var(--space-2xs);

	@media (--md) {
		gap: var(--space-xs);
	}
}

& .countryStatus {
	display: flex;
	align-items: center;
	gap: 8px;

	& .statusImage {
		border-radius: 50%;
		overflow: hidden;
		position: relative;
	}
}

.countriesHeader {
	display: flex;
	flex-direction: column;
	gap: var(--space-xs);
}

.modalPadding {
	padding-inline: var(--space-sm);

	@media (--md) {
		padding-inline: var(--space-lg);
	}
}

.modalContent {
	padding-inline: 0;
}
